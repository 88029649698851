import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, getDocs, doc, getDoc, deleteDoc, query, where, Timestamp, setDoc, updateDoc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut as firebaseSignOut, onAuthStateChanged, User } from 'firebase/auth';
import { encryptApiKey, decryptApiKey } from '../utils/encryption';

// Validate required environment variables
const requiredEnvVars = [
  'VITE_FIREBASE_API_KEY',
  'VITE_FIREBASE_AUTH_DOMAIN',
  'VITE_FIREBASE_PROJECT_ID',
  'VITE_FIREBASE_STORAGE_BUCKET',
  'VITE_FIREBASE_MESSAGING_SENDER_ID',
  'VITE_FIREBASE_APP_ID'
] as const;

for (const envVar of requiredEnvVars) {
  if (!import.meta.env[envVar]) {
    throw new Error(`Missing required environment variable: ${envVar}`);
  }
}

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

function removeUndefined(obj: any): any {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v !== undefined)
  );
}

// User Management
export interface UserProfile {
  id: string;
  email: string;
  name: string;
  role: 'user' | 'admin' | 'super_admin';
  created_at: string;
  subscription?: {
    plan_id: string;
    status: 'active' | 'canceled' | 'past_due';
    current_period_end: string;
  };
  apiKeys?: {
    googlePlaces?: string;
    openai?: string;
  };
}

export interface Lead {
  id: string;
  user_id: string;
  email: string;
  business_name: string;
  business_category: string;
  business_address: string;
  business_rating: number;
  business_reviews_count?: number;
  business_claimed?: boolean;
  business_photos?: string[];
  business_website?: string;
  business_phone?: string;
  business_hours?: Record<string, string>;
  place_id?: string;
  report_data: any;
  agency_id: string;
  source: 'embed' | 'app';
  email_sent: boolean;
  created_at: string;
}

export async function storeLead(data: Omit<Lead, 'id' | 'created_at' | 'user_id'>): Promise<Lead> {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('User must be authenticated to store leads');
    }

    const leadData = {
      ...data,
      user_id: currentUser.uid,
      created_at: Timestamp.now(),
      email_sent: false
    };

    const docRef = await addDoc(collection(db, 'leads'), removeUndefined(leadData));
    return {
      id: docRef.id,
      ...data,
      user_id: currentUser.uid,
      created_at: new Date().toISOString()
    };
  } catch (error) {
    console.error('Failed to store lead:', error);
    throw new Error('Failed to store lead data');
  }
}

export async function getUserProfile(userId: string): Promise<UserProfile | null> {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) return null;

    const data = userDoc.data();
    const profile: UserProfile = {
      id: userId,
      ...data,
      apiKeys: {
        googlePlaces: '',
        openai: ''
      }
    } as UserProfile;

    if (data.apiKeys) {
      try {
        const decryptedKeys = {
          googlePlaces: await decryptApiKey(data.apiKeys.googlePlaces || ''),
          openai: await decryptApiKey(data.apiKeys.openai || '')
        };
        profile.apiKeys = decryptedKeys;
      } catch (decryptError) {
        console.error('Failed to decrypt API keys:', decryptError);
      }
    }

    return profile;
  } catch (error) {
    console.error('Failed to get user profile:', error);
    throw new Error('Failed to retrieve user profile');
  }
}

export async function getAllUsers(): Promise<UserProfile[]> {
  try {
    const usersRef = collection(db, 'users');
    const snapshot = await getDocs(usersRef);
    
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    } as UserProfile));
  } catch (error) {
    console.error('Error getting users:', error);
    throw new Error('Failed to fetch users');
  }
}

export async function updateUserRole(userId: string, role: 'user' | 'admin' | 'super_admin'): Promise<void> {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, { role });
  } catch (error) {
    console.error('Error updating user role:', error);
    throw new Error('Failed to update user role');
  }
}

export async function deleteUser(userId: string): Promise<void> {
  try {
    await deleteDoc(doc(db, 'users', userId));
  } catch (error) {
    console.error('Error deleting user:', error);
    throw new Error('Failed to delete user');
  }
}

// Auth Management
export async function signIn(email: string, password: string): Promise<UserProfile> {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const profile = await getUserProfile(userCredential.user.uid);
    
    if (!profile) {
      await firebaseSignOut(auth);
      throw new Error('User profile not found');
    }

    return profile;
  } catch (error: any) {
    console.error('Sign in failed:', error);
    throw new Error(error.message || 'Authentication failed');
  }
}

export async function signUp(email: string, password: string, name: string): Promise<UserProfile> {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const { user } = userCredential;

    const profile: Omit<UserProfile, 'id'> = {
      email: user.email!,
      name,
      role: 'user',
      created_at: new Date().toISOString(),
      apiKeys: {
        googlePlaces: '',
        openai: ''
      }
    };

    await setDoc(doc(db, 'users', user.uid), removeUndefined(profile));

    return {
      id: user.uid,
      ...profile
    };
  } catch (error: any) {
    console.error('Sign up failed:', error);
    throw new Error(error.message || 'Failed to create account');
  }
}

export async function signOut(): Promise<void> {
  try {
    await firebaseSignOut(auth);
  } catch (error: any) {
    console.error('Sign out failed:', error);
    throw new Error('Failed to sign out');
  }
}

export function onAuthStateChange(callback: (user: User | null) => void): () => void {
  return onAuthStateChanged(auth, callback);
}

// API Key Management
export async function updateApiKeys(userId: string, keys: { googlePlaces?: string; openai?: string }): Promise<void> {
  try {
    const encryptedKeys = {
      googlePlaces: await encryptApiKey(keys.googlePlaces || ''),
      openai: await encryptApiKey(keys.openai || '')
    };

    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      apiKeys: removeUndefined(encryptedKeys)
    });
  } catch (error) {
    console.error('Failed to update API keys:', error);
    throw new Error('Failed to update API keys');
  }
}

// Lead Management
export async function getLeadsByUser(userId: string): Promise<Lead[]> {
  try {
    const leadsRef = collection(db, 'leads');
    const q = query(leadsRef, where('user_id', '==', userId));
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      created_at: doc.data().created_at.toDate().toISOString()
    } as Lead));
  } catch (error) {
    console.error('Failed to get leads:', error);
    return [];
  }
}

export async function getAllLeads(): Promise<Lead[]> {
  try {
    const leadsRef = collection(db, 'leads');
    const snapshot = await getDocs(leadsRef);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      created_at: doc.data().created_at.toDate().toISOString()
    } as Lead));
  } catch (error) {
    console.error('Failed to get all leads:', error);
    return [];
  }
}

export async function getLead(id: string): Promise<Lead | null> {
  try {
    const docRef = doc(db, 'leads', id);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) return null;
    return {
      id: docSnap.id,
      ...docSnap.data(),
      created_at: docSnap.data().created_at.toDate().toISOString()
    } as Lead;
  } catch (error) {
    console.error('Failed to get lead:', error);
    return null;
  }
}

export async function deleteLead(id: string): Promise<void> {
  try {
    await deleteDoc(doc(db, 'leads', id));
  } catch (error) {
    console.error('Failed to delete lead:', error);
    throw new Error('Failed to delete lead');
  }
}

// Subscription Plans
export interface SubscriptionPlan {
  id: string;
  name: string;
  description: string;
  price: number;
  interval: 'month' | 'year';
  created_at: string;
  active: boolean;
  stripe_price_id?: string;
}

// Super Admin Functions
export async function createSuperAdmin(): Promise<UserProfile> {
  try {
    const email = 'garywgipson+super@gmail.com';
    const password = 'asdfasdf';
    
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const { user } = userCredential;

    const profile: Omit<UserProfile, 'id'> = {
      email: user.email!,
      name: 'Super Admin',
      role: 'super_admin',
      created_at: new Date().toISOString(),
      apiKeys: {
        googlePlaces: '',
        openai: ''
      }
    };

    await setDoc(doc(db, 'users', user.uid), removeUndefined(profile));

    return {
      id: user.uid,
      ...profile
    };
  } catch (error: any) {
    console.error('Failed to create super admin:', error);
    throw error;
  }
}

export async function createSubscriptionPlan(plan: Omit<SubscriptionPlan, 'id' | 'created_at'>): Promise<SubscriptionPlan> {
  try {
    const planData = {
      ...plan,
      created_at: Timestamp.now()
    };

    const docRef = await addDoc(collection(db, 'subscription_plans'), planData);
    
    return {
      id: docRef.id,
      ...plan,
      created_at: new Date().toISOString()
    };
  } catch (error) {
    console.error('Error creating subscription plan:', error);
    throw new Error('Failed to create subscription plan');
  }
}

export async function updateSubscriptionPlan(
  planId: string,
  updates: Partial<Omit<SubscriptionPlan, 'id' | 'created_at'>>
): Promise<void> {
  try {
    const planRef = doc(db, 'subscription_plans', planId);
    await updateDoc(planRef, updates);
  } catch (error) {
    console.error('Error updating subscription plan:', error);
    throw new Error('Failed to update subscription plan');
  }
}

export async function deleteSubscriptionPlan(planId: string): Promise<void> {
  try {
    await deleteDoc(doc(db, 'subscription_plans', planId));
  } catch (error) {
    console.error('Error deleting subscription plan:', error);
    throw new Error('Failed to delete subscription plan');
  }
}

export async function getSubscriptionPlans(): Promise<SubscriptionPlan[]> {
  try {
    const plansRef = collection(db, 'subscription_plans');
    const snapshot = await getDocs(plansRef);
    
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      created_at: doc.data().created_at.toDate().toISOString()
    } as SubscriptionPlan));
  } catch (error) {
    console.error('Error getting subscription plans:', error);
    throw new Error('Failed to fetch subscription plans');
  }
}