import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL || '';
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY || '';

if (!supabaseUrl || !supabaseAnonKey) {
  console.warn('Missing Supabase configuration. Email functionality may not work correctly.');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Re-export types and functions from firebase.ts for backward compatibility
export * from './firebase';