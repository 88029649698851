import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { signIn, signUp, signOut, getUserProfile, onAuthStateChange, updateApiKeys as updateFirebaseApiKeys } from '../lib/firebase';
import type { UserProfile } from '../lib/firebase';

interface AuthState {
  user: UserProfile | null;
  impersonatedUser: UserProfile | null;
  loading: boolean;
  error: string | null;
  apiKeys: {
    googlePlaces?: string;
    openai?: string;
  };
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, name: string) => Promise<void>;
  signOut: () => Promise<void>;
  initialize: () => Promise<void>;
  setError: (error: string | null) => void;
  updateApiKeys: (keys: { googlePlaces?: string; openai?: string }) => Promise<void>;
  impersonateUser: (user: UserProfile | null) => void;
  getEffectiveUser: () => UserProfile | null;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      user: null,
      impersonatedUser: null,
      loading: true,
      error: null,
      apiKeys: {
        googlePlaces: import.meta.env.VITE_GOOGLE_PLACES_API_KEY || '',
        openai: import.meta.env.VITE_OPENAI_API_KEY || ''
      },
      signIn: async (email: string, password: string) => {
        try {
          set({ loading: true, error: null });
          const user = await signIn(email, password);
          set({ 
            user,
            loading: false,
            apiKeys: user.apiKeys || get().apiKeys,
            impersonatedUser: null // Reset impersonation on sign in
          });
        } catch (err) {
          set({ 
            error: err instanceof Error ? err.message : 'Authentication failed', 
            loading: false 
          });
          throw err;
        }
      },
      signUp: async (email: string, password: string, name: string) => {
        try {
          set({ loading: true, error: null });
          const user = await signUp(email, password, name);
          set({ 
            user,
            loading: false,
            apiKeys: get().apiKeys,
            impersonatedUser: null
          });
        } catch (err) {
          set({ 
            error: err instanceof Error ? err.message : 'Sign up failed', 
            loading: false 
          });
          throw err;
        }
      },
      signOut: async () => {
        try {
          set({ loading: true, error: null });
          await signOut();
          set({ 
            user: null, 
            loading: false,
            apiKeys: {
              googlePlaces: import.meta.env.VITE_GOOGLE_PLACES_API_KEY || '',
              openai: import.meta.env.VITE_OPENAI_API_KEY || ''
            },
            impersonatedUser: null
          });
        } catch (err) {
          set({ 
            error: err instanceof Error ? err.message : 'Sign out failed',
            loading: false 
          });
        }
      },
      initialize: async () => {
        try {
          set({ loading: true, error: null });
          
          const unsubscribe = onAuthStateChange(async (firebaseUser) => {
            if (firebaseUser) {
              const profile = await getUserProfile(firebaseUser.uid);
              set({ 
                user: profile,
                loading: false,
                apiKeys: profile?.apiKeys || get().apiKeys,
                impersonatedUser: null
              });
            } else {
              set({ 
                user: null,
                loading: false,
                apiKeys: {
                  googlePlaces: import.meta.env.VITE_GOOGLE_PLACES_API_KEY || '',
                  openai: import.meta.env.VITE_OPENAI_API_KEY || ''
                },
                impersonatedUser: null
              });
            }
          });

          return () => unsubscribe();
        } catch (err) {
          console.error('Initialization error:', err);
          set({ 
            error: err instanceof Error ? err.message : 'Initialization failed',
            loading: false 
          });
        }
      },
      setError: (error: string | null) => set({ error }),
      updateApiKeys: async (keys) => {
        const { user } = get();
        if (!user) throw new Error('User not authenticated');

        try {
          await updateFirebaseApiKeys(user.id, keys);
          set({ apiKeys: keys });
        } catch (error) {
          throw error;
        }
      },
      impersonateUser: (user: UserProfile | null) => {
        set({ impersonatedUser: user });
      },
      getEffectiveUser: () => {
        const state = get();
        return state.impersonatedUser || state.user;
      }
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({
        user: state.user,
        apiKeys: state.apiKeys
      })
    }
  )
);