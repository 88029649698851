import { supabase } from '../lib/supabase';

interface EmailOptions {
  to: string;
  subject: string;
  html: string;
}

export async function sendEmail({ to, subject, html }: EmailOptions): Promise<boolean> {
  try {
    if (!supabase) {
      console.error('Supabase client not initialized');
      return false;
    }

    const { data, error } = await supabase.functions.invoke('send-email', {
      body: { to, subject, html }
    });

    if (error) {
      console.error('Supabase function error:', error);
      return false;
    }

    if (!data?.success) {
      console.error('Email sending failed:', data);
      return false;
    }

    return true;
  } catch (error) {
    console.error('Failed to send email:', error);
    return false;
  }
}

export function generateReportEmail(businessName: string, score: number, reportUrl: string): string {
  return `
    <!DOCTYPE html>
    <html>
      <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
      </head>
      <body style="margin: 0; padding: 0; font-family: Arial, sans-serif;">
        <div style="max-width: 600px; margin: 0 auto; padding: 20px;">
          <div style="background-color: #4F46E5; padding: 20px; border-radius: 8px 8px 0 0;">
            <h1 style="color: white; margin: 0; font-size: 24px;">Your Business Profile Analysis</h1>
          </div>
          
          <div style="background-color: white; padding: 20px; border-radius: 0 0 8px 8px; box-shadow: 0 2px 4px rgba(0,0,0,0.1);">
            <p style="color: #374151; font-size: 16px; line-height: 1.5;">
              Thank you for using our Business Profile Scanner. Here's your analysis for ${businessName}:
            </p>
            
            <div style="background-color: #F3F4F6; padding: 20px; border-radius: 8px; margin: 20px 0;">
              <h3 style="margin-top: 0; color: #111827;">Overall Profile Score</h3>
              <p style="font-size: 32px; font-weight: bold; color: #4F46E5; margin: 0;">${score}/100</p>
            </div>
            
            <p style="color: #374151; font-size: 16px; line-height: 1.5;">
              We've prepared a detailed report with recommendations to improve your online presence.
            </p>
            
            <div style="text-align: center; margin: 30px 0;">
              <a href="${reportUrl}" 
                 style="background-color: #4F46E5; color: white; padding: 12px 24px; text-decoration: none; border-radius: 6px; display: inline-block; font-weight: 500;">
                View Full Report
              </a>
            </div>
            
            <p style="color: #6B7280; font-size: 14px; margin-top: 30px;">
              This report was generated automatically. If you have any questions, please don't hesitate to contact us.
            </p>
          </div>
        </div>
      </body>
    </html>
  `;
}

export function generateWelcomeEmail(name: string): string {
  return `
    <!DOCTYPE html>
    <html>
      <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
      </head>
      <body style="margin: 0; padding: 0; font-family: Arial, sans-serif;">
        <div style="max-width: 600px; margin: 0 auto; padding: 20px;">
          <div style="background-color: #4F46E5; padding: 20px; border-radius: 8px 8px 0 0;">
            <h1 style="color: white; margin: 0; font-size: 24px;">Welcome to Scan Stack!</h1>
          </div>
          
          <div style="background-color: white; padding: 20px; border-radius: 0 0 8px 8px; box-shadow: 0 2px 4px rgba(0,0,0,0.1);">
            <p style="color: #374151; font-size: 16px; line-height: 1.5;">
              Hi ${name},
            </p>
            
            <p style="color: #374151; font-size: 16px; line-height: 1.5;">
              Thank you for creating an account with Scan Stack. We're excited to help you optimize your business profile and improve your online presence.
            </p>
            
            <div style="background-color: #F3F4F6; padding: 20px; border-radius: 8px; margin: 20px 0;">
              <h3 style="margin-top: 0; color: #111827;">Getting Started</h3>
              <ul style="margin: 0; padding-left: 20px; color: #374151;">
                <li style="margin-bottom: 8px;">Configure your API keys in Settings</li>
                <li style="margin-bottom: 8px;">Run your first business profile scan</li>
                <li style="margin-bottom: 8px;">Review detailed optimization recommendations</li>
                <li>Track improvements over time</li>
              </ul>
            </div>
            
            <p style="color: #374151; font-size: 16px; line-height: 1.5;">
              If you have any questions or need assistance, our support team is here to help.
            </p>
            
            <p style="color: #6B7280; font-size: 14px; margin-top: 30px;">
              Best regards,<br>
              The Scan Stack Team
            </p>
          </div>
        </div>
      </body>
    </html>
  `;
}

export function generateLeadNotificationEmail(businessName: string, email: string): string {
  return `
    <!DOCTYPE html>
    <html>
      <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
      </head>
      <body style="margin: 0; padding: 0; font-family: Arial, sans-serif;">
        <div style="max-width: 600px; margin: 0 auto; padding: 20px;">
          <div style="background-color: #4F46E5; padding: 20px; border-radius: 8px 8px 0 0;">
            <h1 style="color: white; margin: 0; font-size: 24px;">New Lead Notification</h1>
          </div>
          
          <div style="background-color: white; padding: 20px; border-radius: 0 0 8px 8px; box-shadow: 0 2px 4px rgba(0,0,0,0.1);">
            <div style="background-color: #F3F4F6; padding: 20px; border-radius: 8px; margin: 20px 0;">
              <h3 style="margin-top: 0; color: #111827;">Lead Details</h3>
              <p style="color: #374151; margin-bottom: 8px;"><strong>Business:</strong> ${businessName}</p>
              <p style="color: #374151; margin-bottom: 0;"><strong>Email:</strong> ${email}</p>
            </div>
            
            <p style="color: #374151; font-size: 16px; line-height: 1.5;">
              A new lead has been captured through your Business Profile Scanner.
            </p>
            
            <p style="color: #6B7280; font-size: 14px; margin-top: 30px;">
              This is an automated notification from Scan Stack.
            </p>
          </div>
        </div>
      </body>
    </html>
  `;
}