import { loadStripe } from '@stripe/stripe-js';
import { createSubscriptionPlan as createFirebasePlan } from './firebase';
import type { SubscriptionPlan } from './firebase';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

export async function createCheckoutSession(priceId: string) {
  try {
    const stripe = await stripePromise;
    if (!stripe) {
      throw new Error('Stripe failed to initialize');
    }

    const { sessionId } = await fetch('/api/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ priceId }),
    }).then(res => res.json());

    const { error } = await stripe.redirectToCheckout({ sessionId });
    if (error) throw error;
  } catch (error) {
    console.error('Error creating checkout session:', error);
    throw error;
  }
}

export async function createSubscriptionPlan(plan: Omit<SubscriptionPlan, 'id' | 'created_at'>) {
  try {
    // Create plan in Firebase
    const createdPlan = await createFirebasePlan(plan);
    return createdPlan;
  } catch (error) {
    console.error('Error creating subscription plan:', error);
    throw error;
  }
}

export {
  type SubscriptionPlan,
  updateSubscriptionPlan,
  deleteSubscriptionPlan,
  getSubscriptionPlans
} from './firebase';